import "../../core/src/components/notification/list.style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/notification/list.style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VTW/bMAy951cQBQo0wDTYbtJmGgYM/QM77DjswFiyzVWWDElOnA3974Plj8Rp0zTdTrYkko984qO+PspdZrGUDuje5U2ygj8zgOg6fABMhSn5HYfo8wzgaQYQR88P4+7wafaxCxIFgxIbVkjKC89hsVhVTWu1JeELDrd3UbsePeLgIchVCnccMiWDdftlgqxMPRnNITWqLnV7kmPFYTWJkVwaAxXlmpGXpeOQSu2lbbd/1c5TtmOp0V5qf3gUUJOukqG0eLXoNioUgnTOIb6rGlgsJ8ndvpzc5Sn0BC6iaRbDukSbk2Zr470pOcR9bmtjhbTMoqDacVhG12EX08fcmloLlhplLIcN2hvGMMtIS7ZJmMKdtOzAzsnUaIF2N28DnPCi1GhWWSo7u5GERSDhhJOXjT9wAsiM9szRb7kvQ7W2Q8lJfxHBMRC552mEXJ6FnFZ0FjR6A+jda6DOo68dk9Yae1GdY38tqqZrwhHw/m2tb832hWYY2tc46s2kQk8bOQHt9bvXHUBaW9dWWBk6IuCeF2YjLdfG3/wQ6JEJcrhWUny58raWVz8/8ALdzbr23ujOeD4PRbyjJ4P7t420Cift9ikEnI4w1FRiV+Yw8JZRVDqQ6CRkxm7RCgexO4iDIc6eH1w7o2of+LH9EIgGnXW6m0675Eisw/p8rR1BQVAP4fe7USTm+1vkEH1cVg24dv8EVaQdCfnQ3Xrn1kdomCtQmO0RcI/anQXTQxKPbxkmNB0+CqPl+l3jvW/Mt+ljxEr/QfHJScWP4cX5GaYwlYVRA81nAab5ywAQnLa9xTKKXpm2zwbnyDPpFqjd2kjrKUU1jCxvqkPITqOkMW11P4r0wlLHeFlw7Js/Iz+8Y6eGz+iYB8cXkx2ftl5xfSdc+AIV/+sZHi/v9Tye9dvTX0PH6eZyCQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var containerScrollViewport = 'i7sgx20';
export var error = 'i7sgx26';
export var itemActionButton = 'i7sgx2f';
export var itemContainer = 'i7sgx27';
export var itemDate = 'i7sgx2c';
export var itemDeleteButton = 'i7sgx2a';
export var itemList = 'i7sgx21';
export var itemMain = 'i7sgx2b';
export var itemNameLabel = 'i7sgx2e';
export var itemNameLabelIcon = 'i7sgx2g';
export var itemNotSupported = 'i7sgx2d';
export var itemSkeletonContainer = 'i7sgx29';
export var listEmpty = 'i7sgx22';
export var listEmptyDescription = 'i7sgx25';
export var listEmptyIconContainer = 'i7sgx23';
export var listEmptyTitle = 'i7sgx24';
export var loadMoreIndicator = 'i7sgx2h';